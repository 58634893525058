<template>
  <main>
    <div class="container mt-3 p-3 text-center">
      <h3>{{ $t("labels.contact_us") }}</h3>
    </div>

    <form class="container">
      <div class="container">
        <Alert :alerts="alert"></Alert>
      </div>
      <div class="container shadow p-3 mb-5 bg-white rounded">
        <div class="row g-3 h-100 py-3">
          <div class="col-md-6 ">
            <div class="col-md mb-3">
              <div class="form-floating">
                <input
                  v-model="userData.full_name"
                  :class="{ 'is-invalid': !full_nameValid }"
                  type="text"
                  class="form-control"
                  placeholder=" "
                  :readonly="user"
                />
                <label for="floatingInputGrid">{{
                  $t("labels.full_name")
                }}</label>
              </div>
            </div>

            <div class="col-md mb-3">
              <div class="form-floating">
                <input
                  :readonly="user"
                  v-model="userData.email"
                  :class="{ 'is-invalid': !emailValide }"
                  type="email"
                  class="form-control"
                  placeholder=" "
                />
                <label for="floatingInputGrid">{{
                  $t("labels.email_address")
                }}</label>
              </div>
            </div>
            <div class="col-md">
              <div class="form-floating">
                <input
                  v-model="userData.phone"
                  type="text"
                  class="form-control"
                  placeholder=" "
                />
                <label for="floatingInputGrid">{{ $t("labels.phone") }}</label>
              </div>
            </div>
          </div>
          <div class="col-md-6 ">
            <div class="form-floating h-100">
              <textarea
                v-model="userData.message"
                :class="{ 'is-invalid': !messageValide }"
                class="form-control"
                placeholder=" "
                rows="5"
              ></textarea>
              <label>{{ $t("labels.message") }}</label>
            </div>
          </div>
        </div>

        <div
          class="d-grid gap-2 col-9 col-sm-6 col-md-4 col-lg-3 mx-auto mt-2 py-3"
        >
          <button @click="recaptcha" class="btn bg-p" type="button">
            <img class="me-2" src="../assets/img/send-white.svg" alt="send"> {{ $t("labels.send_message") }} 
            <span
              v-if="loading"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          </button>
        </div>
      </div>
    </form>
  </main>
</template>

<script>
import Vuex from "vuex";
import api from "@/services/api";

import Alert from "@/components/alert/Alert";

export default {
  name: "Contact",
  components: {
    Alert,
  },
  data() {
    return {
      val: "#39B4C8",
      val2: "#3ED53EFF",
      loading: false,
      full_nameValid: true,
      emailValide: true,
      messageValide: true,
      alert: {
        msg: "",
        type: "",
      },
    };
  },

  computed: {
    ...Vuex.mapGetters(["user"]),
    userData: function() {
      if (this.user) {
        let data = Object.assign({}, this.user);
        data.message = "";
        return data;
      }

      return Object.assign(
        {},
        {
          phone: "",
          full_name: "",
          email: "",
          message: "",
        }
      );
    },
  },

  methods: {
    async recaptcha() {
      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded();

      // Execute reCAPTCHA with action "login".
      const token = await this.$recaptcha("contact");
      this.send(token);
    },
    send(token) {
      this.full_nameValid = this.$valid(this.userData.full_name);
      if (!this.full_nameValid) return;

      this.emailValide = this.$validEmail(this.userData.email);
      if (!this.emailValide) return;

      this.messageValide = this.$validMessage(this.userData.message);
      if (!this.messageValide) return;
      this.userData.tokenRecaptha = token;
      this.loading = true;
      api({
        method: "post",
        url: "/contact",
        data: this.userData,
      })
        .then((response) => {
          if (!response.data.error) {
            this.userData.message = "";
            this.alert.msg = response.data.messages;
            this.alert.type = "success";
            this.loading = false;
          } else throw new Error(response.data.messages);
        })
        .catch((error) => {
          this.alert.msg = error.message;
          this.alert.type = "danger";
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
textarea {
  height: 100% !important;
}
.container {
  max-width: 960px;
}
</style>
